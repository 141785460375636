import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Platform } from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import { WorkerCardModel } from '../../../models/workerCard.model';
import {
	EventName,
	logEvent,
} from '../../../services/analyticsAndLogs.service';
import httpService from '../../../services/http.service';
import { modalService } from '../../../services/modal.service';
import { fillPdf } from '../WorkerCardPreview/workerCardPreviewBuilder';
import { getFileData } from './localToServerFiles/localToServerFiles';
import { getTabsMap } from './WorkerCardTabs';
import SubmitFormAuthModal from '../../PersonalInformation/modals/SubmitFormAuthModal';


export const onSubmission = async ({
	formId,
	isNew101Form,
	currentValues,
	initialValues,
	duplicate,
	ignoreSave,
	fullData,
	close,
	isFromDraft
}: any) => {
	let formIdForRequest = formId;
	if (isNew101Form === 'true' || isNew101Form === true) {
		formIdForRequest = 0;
	}
	try {
		let saveData: any = fullData;
		if (!ignoreSave) {
			saveData = await httpService.api({
				type: 'updateWorkerCard',
				params: {
					formId: formIdForRequest,
				},
				isFormData: true,
				notGenerateFormData: true,
				data: await dataToServer(
					currentValues, //formMethods.getValues(),
					initialValues,
					duplicate,
					null,
					isFromDraft
				),
			});
			if (
				(Platform.OS !== 'web' && saveData[0] !== '{') ||
				(Platform.OS === 'web' && typeof saveData !== 'object')
			) {
				throw { data: saveData };
			}
			if (Platform.OS !== 'web') {
				saveData = JSON.parse(saveData);
			}

			if (saveData.status === 400) {
				throw { data: saveData };
			}
		}

		formIdForRequest = saveData.id;

		const values = new WorkerCardModel(saveData);
		let submission: any;
		await modalService.openModal(
			null,
			{
				saveData,
				dataToServer,
				values,
			},
			(props: any) => (
				<SubmitFormAuthModal
					onResponse={isSuccess => (submission = isSuccess)}
					{...props}
				/>
			),
		);

		if (Platform.OS !== 'web' && submission?.res[0] === '{') {
			const data = JSON.parse(submission.res);
			if (data.status === 400) {
				throw { data };
			}
		}

		if (submission?.resText === 'success') {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>workerCard.editor.submit.success</I18n>
					</AlertModal>
				),
			);
			close?.();
		}
	} catch (e) {
		logEvent({
			eventName: EventName.submitWorkerCardFailure,
			data: {
				data: await dataToServer(
					currentValues, //formMethods.getValues(),
					initialValues,
					duplicate,
					null,
					isFromDraft
				),
				formId: formIdForRequest,
				errors: e,
			},
		});
		let errorMessage = 'workerCard.editor.save.failure';

		if (
			typeof e?.data === 'string' &&
			e?.data?.includes('Tax credit address code is not valid')
		) {
			errorMessage = 'workerCard.editor.save.addressCodeNotValid';
		}

		if (
			typeof e?.data === 'string' &&
			e?.data.startsWith('Cant update form in status')
		) {
			errorMessage = 'workerCard.editor.submit.failureStatus';
		}
		await modalService.openModal(
			null,
			{
				submitBtnText: 'general.close',
				iconName: 'attention',
			},
			(props: any) => (
				<AlertModal {...props}>
					<I18n>{errorMessage}</I18n>
				</AlertModal>
			),
		);
	}
};

export const dataToServer = async (
	values: any,
	initiaValues: any,
	duplicate: boolean,
	saveData?: any,
	isFromDraft?: boolean,
) => {
	removeUnneccessaryCheckboxFiles(values);
	const formData: any = new FormData();
	const applicaionData = [];
	const originalFiles = {
		..._.pick(initiaValues?.taxCreditReason || {}, [
			'handicappedFile',
			'handicappedCompensationFile',
			'taxCreditAddressFile',
			'immigrantFile',
			'spouseHasNoIncomeFile',
			'childrenNotInMyCustodyFile',
			'childrenWithDisabilityFile',
			'alimonyPaymentFile',
			'serviceFile',
			'academicDegreeFile',
		]),
		..._.pick(initiaValues?.taxCreditRequest || {}, [
			'noIncomeFile',
			'taxAssessorApprovedFile',
		]),
		..._.pick(initiaValues?.employee || {}, ['idFile', 'genderFile']),
		...(initiaValues?.taxCreditRequest?.additionalIncome?.reduce(
			(res: {}, data: any, i: number) => ({
				...res,
				[`additionalIncomeFiles${i + 1}`]: data.additionalIncomeFile,
			}),
			{},
		) || {}),
	};
	const files = {
		..._.pick(values?.taxCreditReason || {}, [
			'handicappedFile',
			'handicappedCompensationFile',
			'taxCreditAddressFile',
			'immigrantFile',
			'spouseHasNoIncomeFile',
			'childrenNotInMyCustodyFile',
			'childrenWithDisabilityFile',
			'alimonyPaymentFile',
			'serviceFile',
			'academicDegreeFile',
		]),
		..._.pick(values?.taxCreditRequest || {}, [
			'noIncomeFile',
			'taxAssessorApprovedFile',
		]),
		..._.pick(values?.employee || {}, ['idFile', 'genderFile']),
		...(values?.taxCreditRequest?.additionalIncome?.reduce(
			(res: {}, data: any, i: number) => ({
				...res,
				[`additionalIncomeFiles${i + 1}`]: data.additionalIncomeFile,
			}),
			{},
		) || {}),
	};

	Object.keys(files).forEach((key: string) => {
		files[key].forEach((file: any) => {
			if (file?.extraData && !file?.extraData?.id) {
				let fileData = file?.extraData;
				if (Platform.OS === 'android' || Platform.OS === 'ios') {
					applicaionData.push(getFileData(key, fileData));
				} else {
					formData.append(
						`${_.kebabCase(key)}${
							key.startsWith('additionalIncomeFiles') ? '' : 's'
						}`,
						fileData,
						file?.exrtaData?.name,
					);
				}
			}
		});
	});
	const deleteFile: number[] = Object.keys(originalFiles).reduce(
		(res: any, key: string) => {
			if (originalFiles[key].length) {
				const deletedFiles = originalFiles[key]?.filter((file: any) => {
					const foundFile = files[key]?.find((f: any) => {
						return f?.extraData?.id === file?.extraData?.id;
					});
					return file?.extraData?.id && !foundFile;
				});
				if (deletedFiles.length) {
					return [
						...res,
						...(_.compact(
							deletedFiles?.map(
								(file: any) => file?.extraData?.id,
							),
						) || []),
					];
				}
			}

			return res;
		},
		[],
	);

	console.log({ deleteFile });

	const spouse = values.spouse
		? {
				...values.spouse,
				...(values.spouse?.incomeType?.length
					? { incomeType: values.spouse?.incomeType }
					: { incomeType: undefined }),
				idNumber:
					values.spouse?.identityType === 'ID'
						? values.spouse?.idNumber
						: undefined,
				passport:
					values.spouse?.identityType === 'PASSPORT'
						? values.spouse?.idNumber
						: undefined,
		  }
		: null;

	const form = {
		..._.omit(values, ['submitDate']),
		duplicate,
		isFromDraft: isFromDraft ? true : false,
		spouse: !_.values(spouse).find(val => val !== undefined)
			? null
			: spouse,
		deleteFile: !deleteFile.length ? null : deleteFile,
		employer: undefined,
		employee: values?.employee
			? {
					..._.omit(values?.employee, ['idFile', 'genderFile']),
					healthOrgName: !values?.employee?.healthOrgMember
						? undefined
						: values?.employee?.healthOrgName,
			  }
			: undefined,
		taxCreditReason: values?.taxCreditReason && {
			..._.omit(values?.taxCreditReason, [
				'handicappedFile',
				'handicappedCompensationFile',
				'taxCreditAddressFile',
				'immigrantFile',
				'spouseHasNoIncomeFile',
				'childrenNotInMyCustodyFile',
				'childrenWithDisabilityFile',
				'alimonyPaymentFile',
				'serviceFile',
				'academicDegreeFile',
			]),
			taxCreditAddressName: values?.taxCreditReason?.taxCreditAddress
				? values?.taxCreditReason?.taxCreditAddressName
				: undefined,
			taxCreditAddressCode: values?.taxCreditReason?.taxCreditAddress
				? values?.taxCreditReason?.taxCreditAddressCode
				: undefined,
		},
		taxCreditRequest: values?.taxCreditRequest && {
			..._.omit(values?.taxCreditRequest, [
				'noIncomeFile',
				'taxAssessorApprovedFile',
				'taxCreditRequestReason',
			]),
			additionalIncome: values?.taxCreditRequest?.additionalIncome?.map(
				(additional: any) => ({
					..._.omit(additional, ['additionalIncomeFile']),
					monthlyIncome: additional.monthlyIncome
						? additional.monthlyIncome.replace(',', '')
						: undefined,
					taxReduce: additional.taxReduce
						? additional.taxReduce.replace(',', '')
						: undefined,
				}),
			),
		},
	};

	const fileData = saveData && (await fillPdf({ data: saveData , fromSave: true }));

	const fileDataWithoutText = saveData && (await fillPdf({data: saveData , fromSave: false}))

	if (Platform.OS === 'android' || Platform.OS === 'ios') {
		if (saveData) {
			applicaionData.push({
				name: 'pdf-form',
				type: 'application/json',
				data: JSON.stringify({ pdfForm: fileData?.file  }),
			});
			applicaionData.push({
				name: 'pdf-form-without-text',
				type: 'application/json',
				data: JSON.stringify({ pdfForm: fileDataWithoutText?.file })
			})
			if (saveData.verifyPassword) {
				applicaionData.push({name: "verifyPassword", data:  saveData.verifyPassword});
			}
			if(saveData.verifyType){
				applicaionData.push({name: "verifyType" , data : saveData.verifyType})
			}
		}
		applicaionData.push({
			name: 'form',
			type: 'application/json',
			data: JSON.stringify(form),
		});
		return applicaionData;
	}

	if (saveData) {
		if (saveData.verifyPassword) {
			formData.append('verifyPassword', saveData.verifyPassword);
		}
		if (saveData.verifyType) {
            formData.append('verifyType', saveData.verifyType);
        }
		const pdfBlob = new Blob(
			[JSON.stringify({ pdfForm: fileData?.file })],
			{
				type: 'application/json',
			},
		);
		const pdfWithoutTextBlob = new Blob(
			[JSON.stringify({ pdfForm: fileDataWithoutText?.file })],
			{
				type: 'application/json',
			},
		)
		formData.append('pdf-form-without-text', pdfWithoutTextBlob)
		formData.append('pdf-form', pdfBlob);
	}

	const blob = new Blob([JSON.stringify(form)], {
		type: 'application/json',
	});
	formData.append('form', blob);
	return formData;
};

export const findFirstError = (errors: any, noRequired = true) => {
	const tabsMap = getTabsMap([]);
	const fieldError = tabsMap.reduce((res: any, tab: any) => {
		if (res) {
			return res;
		}

		let field = undefined;
		_.flattenDeep(tab?.fields).find((f: any) => {
			const fieldError = _.get(errors, f.name);
			if (Array.isArray(fieldError)) {
				let innerError: any = undefined;
				fieldError.find((fieldErr, i) => {
					_.flattenDeep(f.fields).find((field: any) => {
						const innerFieldError = _.get(fieldErr, field.name);
						if (
							innerFieldError &&
							innerFieldError?.message &&
							(!noRequired ||
								(noRequired &&
									innerFieldError?.message !==
										'general.validation.required'))
						) {
							innerError = `${field.name}[${i}]`;
							return true;
						}
					});
					return !!innerError;
				});
				if (innerError) {
					field = innerError;
					return true;
				}
			}

			if (
				fieldError &&
				fieldError?.message &&
				(!noRequired ||
					(noRequired &&
						fieldError?.message !== 'general.validation.required'))
			) {
				console.log({ fieldError, f });
				field = f.name;
				return true;
			}
			return false;
		});
		return field ? { tab, field } : undefined;
	}, undefined);

	return fieldError;
};

//in case the files weren't removed when checkbox was unchecked for any reason.
export const removeUnneccessaryCheckboxFiles = (dataToServer: any) => {
	for (let category in checkboxesWithFiles) {
		for (let checkbox of checkboxesWithFiles[category]) {
			if (dataToServer[category][checkbox] == false) {
				dataToServer[category][`${checkbox}File`] = [];
			}
		}
	}
};

export const checkboxesWithFiles: any = {
	taxCreditReason: [
		'handicapped',
		'handicappedCompensation',
		'taxCreditAddress',
		'immigrant',
		'spouseHasNoIncome',
		'childrenNotInMyCustody',
		'childrenWithDisability',
		'alimonyPayment',
		'service',
		'academicDegree',
	],
	taxCreditRequest: ['noIncome', 'taxAssessorApproved', 'additionalIncome'],
};

export const clearRequiredErrors = (errors: any, clearErrors: any) => {
	const tabsMap = getTabsMap([]);
	const requiredErrorsArray = tabsMap.reduce((res: any, tab: any) => {
		const data: any = _.flattenDeep(tab?.fields).reduce(
			(results: any, f: any) => {
				const fieldError = _.get(errors, f.name);
				let requires = [];
				if (Array.isArray(fieldError)) {
					requires = fieldError.reduce((arrayErrors, fieldErr, i) => {
						const fErrorArr: any = [];
						_.flattenDeep(f.fields).forEach((field: any) => {
							const innerFieldError = _.get(fieldErr, field.name);
							if (
								innerFieldError &&
								innerFieldError?.message &&
								innerFieldError?.message ===
									'general.validation.required'
							) {
								fErrorArr.push(`${f.name}[${i}].${field.name}`);
							}
						});
						if (fErrorArr.length) {
							return [...arrayErrors, ...fErrorArr];
						}
						return arrayErrors;
					}, []);
				}

				if (
					fieldError &&
					fieldError?.message &&
					fieldError?.message === 'general.validation.required'
				) {
					return [...results, ...requires, f?.name];
				}
				return [...results, ...requires];
			},
			[],
		);
		return [...res, ...data];
	}, []);

	clearErrors(requiredErrorsArray);
	// return _.flattenDeep(requiredErrorsArray).map(err => err.)
};

import React from 'react';
import { TextInput, View, Platform, StyleSheet } from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import colors from '../../config/colors';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';
// import LinkButtonContainer from '../../../../components/shared/Buttons/LinkButton.container';
// import SolidButtonContainer from '../../../../components/shared/Buttons/SolidButton.container';
// import I18n from '../../../../components/shared/i18n/I18n.component';
// import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import useDevicePlatform, {
	DeviceType,
} from '../../hooks/useDevicePlatform.hook';


interface SubmitFormWithCodeProps {
    code:string;
    setCode: React.Dispatch<React.SetStateAction<string>>;
    step: number;
    phone: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
}


export const SubmitFormWithCode = ({
    setPhoneNumber,
    phone,
    step,
    code,
    setCode
}:SubmitFormWithCodeProps) => {
	
	const currPlatform = useDevicePlatform();
	
	
	const updatePhone = (value: string) => {
		if (!value.match(/^[0-9]*$/)) {
			return;
		}
		setPhoneNumber(value);
	};

   
	return (
		<>
        {
            !step ? (
                <>
                    <I18n size={14} weight='normal'>
                        workerCard.pull.addPhoneNumber
                    </I18n>
                    <View style={styles.inputContainer}>
                        <ImageIcon
                            name={'phone'}
                            width={22}
                            height={24}
                            style={{ marginLeft: 4 }}
                        />
                        <TextInput
                            style={[styles.textInput, { width: 220 }]}
                            underlineColorAndroid='transparent'
                            value={phone}
                            onChangeText={updatePhone}
                        />
                    </View>
                </>

            ) :
            (
                <>
                    <I18n size={14} weight='normal'>
                        workerCard.pull.addCodeNumber
                    </I18n>
                    <View style={styles.inputContainer}>
                        <ImageIcon
                            name={'phoneCode'}
                            width={22}
                            height={24}
                            style={{ marginLeft: 4 , width:24}}
                        />
                        <TextInput
                            style={[styles.textInput, { width: 220 }]}
                            underlineColorAndroid='transparent'
                            value={code}
                            onChangeText={setCode}
                        />
                    </View>
                </>
            )
        }
		</>
	);
};

const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 46,
        paddingRight: 20,
        paddingLeft: 15,
        borderRadius: 5,
        marginTop: 5,
        backgroundColor: '#ecf3f7',
    },
    textInput: {
        textAlign: 'right',
        fontSize: 14,
        width: '100%',
        fontWeight: '500',
        color: colors.darkGrey,
        height: '100%',
        ...Platform.select({
            web: {
                outlineWidth: 0,
            },
        }),
    },
});

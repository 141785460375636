// @ts-ignore
import { API_URL, USE_MOCK } from '@env';
import Config from 'react-native-config';
import { isObject, template } from 'lodash';
import { apiMap } from '../models/apiMap.model';
import {
	CancellablePromise,
	HttpRequestConfig,
} from '../interfaces/http.interface';
import { getState, dispatch } from '../store/store';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import {
	refreshTokenSetData,
	logout,
	getStoreDataByKey,
} from '../store/actions/login.actions';
import { AsyncLocalStorage } from '../config/localStorage';
import { logError, logRequest, logSuccess } from '../utils/log.utils';
import {
	decrementBusyIndicator,
	incrementBusyIndicator,
	setIsMaintenance,
} from '../store/actions/config.actions';
import { Platform } from 'react-native';
import { rNFetchBlob } from './rnFormDataFetch/rnFormDataFetch';
import { modalService } from './modal.service';
import UnautorisedModal from '../modals/UnauthorisedModal/UnautorisedModal';
import logoutWhenNoAction from './logoutWhenNoAction.service';
import { EventName, logEvent } from './analyticsAndLogs.service';
class HttpService {
	api<T>(cfg: HttpRequestConfig): CancellablePromise<T> {
		let cancelFunction = () => {
			if (!cfg.disableBI) {
				dispatch(decrementBusyIndicator());
			}
		};
		const promise = new Promise(async (resolve, reject) => {
			if (!cfg.disableBI) {
				dispatch(incrementBusyIndicator());
			}
			const authHeader =
				cfg.noAuthHeader || apiMap[cfg.type].noAuthHeader
					? {}
					: cfg.headers?.authHeader || (await this.getAuthHeader());
			if (
				!cfg.noAuthHeader &&
				!apiMap[cfg.type].noAuthHeader &&
				!authHeader?.Authorization
			) {
				cancelFunction();
				return;
			}

			if (cfg.noAuthHeader || apiMap[cfg.type].noAuthHeader) {
				this.isAlreadyFetchingAccessToken = false;
				this.requestsArray = [];
			}

			logoutWhenNoAction.startLogoutInterval();
			const formDataHeader = cfg.isFormData
				? {
						'Content-Type': `multipart/form-data`,
						// 'Content-Disposition': `attachment; filename=${cfg.fileName}`,
				  }
				: {};

			let source: any = null;
			if (cfg.timeout) {
				source = Axios.CancelToken.source();
				setTimeout(() => {
					source?.cancel();
					// Timeout Logic
				}, cfg.timeout);
			}
			const employerDataStore: string | undefined =
				await getStoreDataByKey(AsyncLocalStorage.EMPLOYER);

			const employerId =
				getState()?.login?.employee?.hp ||
				(employerDataStore && JSON.parse(employerDataStore)?.hp);

			const customerId =
				cfg.customerId || getState()?.repEnterMode.customerId;
			const customerHpHeader =
				!cfg.noRepHeader &&
				!apiMap[cfg.type].noRepHeader &&
				(cfg.customerHp || getState()?.repEnterMode.customerHp)
					? {
							'customer-hp':
								cfg.customerHp ||
								getState()?.repEnterMode.customerHp,
							...(customerId
								? { 'CUSTOMER-ID': customerId }
								: {}),
					  }
					: {};

			const employerHeader =
				employerId && cfg.type !== 'login'
					? {
							hp: employerId,
					  }
					: {};

			let RNFetchBlob: any;
			if (Platform.OS === 'android' || Platform.OS === 'ios') {
				RNFetchBlob = rNFetchBlob;
			}

			(cfg.isFormData && RNFetchBlob
				? RNFetchBlob.fetch(
						apiMap[cfg.type].method,
						this.getUrl(cfg),
						Object.assign(
							authHeader,
							apiMap[cfg.type].headers,
							cfg.headers,
							employerHeader,
							formDataHeader,
							customerHpHeader,
						),
						cfg.data,
				  )
				: cfg.useFetchBlob
				? RNFetchBlob.config({
						fileCache: true,
						path: cfg.path,
				  }).fetch(
						apiMap[cfg.type].method,
						this.getUrl(cfg),
						Object.assign(
							authHeader,
							apiMap[cfg.type].headers,
							cfg.headers,
							employerHeader,
							formDataHeader,
						),
						cfg.data,
				  )
				: Axios({
						url: this.getUrl(cfg),
						responseType: cfg.responseType,
						data:
							cfg.isFormData && !cfg.notGenerateFormData
								? this.buildFormData(cfg.data)
								: cfg.data,
						params: cfg.query || {},
						method: apiMap[cfg.type].method,
						withCredentials: false,
						headers: Object.assign(
							authHeader,
							apiMap[cfg.type].headers,
							cfg.headers,
							employerHeader,
							formDataHeader,
							customerHpHeader,
						),
						cancelToken:
							source?.token ||
							new Axios.CancelToken(c => {
								cancelFunction = c;
							}),
				  })
			)
				.then(
					(res: AxiosResponse) => {
						const { request } = res;
						logRequest(`Request ${request?.responseURL}`);
						logSuccess(
							`Data ${JSON.stringify(
								cfg.returnAllRes ? res : res.data,
							)}`,
						);
						if (!cfg.disableBI) {
							dispatch(decrementBusyIndicator());
						}

						if (
							((cfg.isFormData && RNFetchBlob) ||
								cfg.useFetchBlob) &&
							res.data[0] === '{'
						) {
							const data = JSON.parse(res.data);
							if (data.status === 401) {
								this.errorHandler(
									cfg,
									{ response: { ...res, data } },
									resolve,
									reject,
								);
								return;
							} else if (
								data.status === 500 ||
								data.status === 400
							) {
								logEvent({
									eventName: EventName.apiFailure,
									data: {
										url: cfg.url,
										data: cfg.data,
										errRes: JSON.stringify(data),
									},
								});
							}
						}
						resolve(cfg.returnAllRes ? res : res.data);
					},
					async (err: AxiosError) => {
						const { message, request } = err;
						logError(
							`Error for request ${request?.responseURL} with status ${message}`,
						);
						if (Platform.OS !== 'web') {
							const crashlytics = (
								await import(
									'@react-native-firebase/crashlytics'
								)
							).default;
							crashlytics().log('UsersTableContainer');
							await crashlytics().recordError(
								new Error(
									`Error for request ${request?.responseURL} with status ${message}, data: ${err.response?.data}`,
								),
							);
						}
						if (!cfg.disableBI) {
							dispatch(decrementBusyIndicator());
						}
						await this.errorHandler(cfg, err, resolve, reject);
					},
				)
				.catch((e: any) => {
					if (!cfg.disableBI) {
						dispatch(decrementBusyIndicator());
					}
				});
		}) as CancellablePromise<T>;
		promise.cancel = cancelFunction;
		return promise;
	}

	private isUnautorisedModalOpen = false;

	private async errorHandler(
		cfg: HttpRequestConfig,
		err: any,
		resolve: any,
		reject: any,
	) {
		if (
			err.response?.data &&
			cfg.type !== 'login' &&
			cfg.type !== 'logout' &&
			err.response?.data?.status === 401 // Don't try to refresh token for login
		) {
			try {
				await this.refreshToken({ cfg, err, resolve, reject });
			} catch (err: any) {
				reject(err.response);
			}
		} else if (err.response?.status === 403) {
			if (!this.isUnautorisedModalOpen) {
				this.openUnautorisedModal();
			}
		} else if (err.response?.status === 503 && cfg.type !== 'logout') {
			dispatch(logout());
			dispatch(setIsMaintenance(true));
		} else {
			logEvent({
				eventName: EventName.apiFailure,
				data: {
					data: cfg.data,
					errRes: err.response,
				},
			});
			reject(err.response);
		}
	}

	private isAlreadyFetchingAccessToken = false;
	private requestsArray: any[] = [];

	private addSubscriber = (callback: any) => {
		this.requestsArray.push(callback);
	};

	private onAccessTokenFetched = (accessToken: any) => {
		this.requestsArray.forEach(callback => callback(accessToken));
		this.requestsArray = [];
	};

	private async refreshToken(params: {
		cfg: HttpRequestConfig;
		error: any;
		resolve: any;
		reject: any;
	}) {
		const { cfg, error, resolve, reject } = params;
		try {
			this.addSubscriber(async (accessToken: any) => {
				cfg.headers = await this.getAuthHeader(accessToken);
				this.api(cfg).then(resolve).catch(reject);
			});

			if (!this.isAlreadyFetchingAccessToken) {
				this.isAlreadyFetchingAccessToken = true;

				const response = await Axios({
					url: this.getUrl({ type: 'refreshToken' }),
					method: apiMap.refreshToken.method,
					data: {
						token: getState().login.token,
						refresh_token: getState().login.refresh_token,
					},
				});

				if (!response.data) {
					dispatch(logout());
					reject(error);
				}

				logRequest(`Request ${response.request?.responseURL}`);

				this.isAlreadyFetchingAccessToken = false;
				dispatch(refreshTokenSetData(response.data));
				this.onAccessTokenFetched(response.data.token);
			}
		} catch (exception) {
			dispatch(logout());
			reject(exception);
		}
	}

	private async getAuthHeader(token?: string) {
		const headerToken: any =
			token ||
			getState().login.token ||
			(await getStoreDataByKey(AsyncLocalStorage.TOKEN));
		return headerToken && headerToken !== 'undefined'
			? { Authorization: `${headerToken}` }
			: {};
	}

	private async openUnautorisedModal() {
		this.isUnautorisedModalOpen = true;
		await modalService.openModal(
			null,
			{
				onSubmit: (onNext: Function) => {
					dispatch(logout() as any);
					this.isUnautorisedModalOpen = false;
					onNext();
				},
				onCustomNext: () => {
					dispatch(logout() as any);
					this.isUnautorisedModalOpen = false;
				},
			},
			UnautorisedModal,
		);
	}

	private getUrl(cfg: HttpRequestConfig) {
		//API URL from env var
		const url = (Config.API_URL || API_URL) + apiMap[cfg.type].url;
		//const url = "http://localhost:8085/" + apiMap[cfg.type].url;
		const t = template(url)(cfg.params);
		return t;
	}

	private buildFormData(data: any) {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			formData.append(key, data[key]);
		});
		return formData;
	}
}

const httpService = new HttpService();
export default httpService;

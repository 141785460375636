import React, { useEffect, useRef, useState } from 'react';
import { RECAPTCHA_SITE_KEY } from '@env';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextInput, View, Platform, Text } from 'react-native';
import LinkButtonContainer from '../../../../components/shared/Buttons/LinkButton.container';
import SolidButtonContainer from '../../../../components/shared/Buttons/SolidButton.container';
import I18n from '../../../../components/shared/i18n/I18n.component';
import ImageIcon from '../../../../components/shared/Icon/ImageIcon.component';
import useDevicePlatform, {
	DeviceType,
} from '../../../../hooks/useDevicePlatform.hook';
import { i18nService } from '../../../../services/i18n.service';
import { useDispatch } from 'react-redux';
import {
	loginFailure,
	loginRequest,
	loginStep2SuccessCallback,
	resetLoginError,
} from '../../../../store/actions/login.actions';
import httpService from '../../../../services/http.service';
import {
	EventName,
	logEvent,
} from '../../../../services/analyticsAndLogs.service';
import {
	getIsSafari,
	getItemFromLocalStorage,
	setItemToLocalStorage,
} from '../../../../utils/globals.utils';
import {
	checkPinCodeFromClipBoard,
	getShouldDisplayCaptch,
	styles,
} from './loginWithCode.utils';
import { clipboard } from '../../../../services/Clipboard/Clipboard';

export enum CodeType {
	SMS = 'SMS',
	VOICE_MAIL = 'VOICE_MAIL',
}

interface SignInProps {
	username: string;
	password: string;
	setUsername: (val: string) => void;
	setPassword: (val: string) => void;
	hasError: boolean;
	error: JSX.Element;
	lastClipboardText: React.MutableRefObject<string>;
}

const MAX_LOGIN_ATTEMPTS = 3;
const MAX_LOGIN_ATTEMPTS_TIME_FIRST = 60 * 60 * 1000; // an hour
const MAX_LOGIN_ATTEMPTS_TIME_SECOND = 60 * 5 * 1000; // 5 minutes

const captchaInitialValue = { attempts: [], shouldDisplay: false, step: 0 };
const captchaResendCodeInitialValue = { attempts: [], shouldDisplay: false };

export const SigninWithCode = ({
	username,
	password,
	setUsername,
	setPassword,
	hasError,
	error,
	lastClipboardText,
}: SignInProps) => {
	const [phone, setPhoneNumber] = useState<string>('');
	const [step, setStep] = useState<number>(0);
	const [captchaVerified, setCaptchaVerified] = useState<boolean>(
		Platform.OS !== 'web',
	);
	const [displayCaptchaData, setDisplayCaptchaData] = useState<{
		attempts: string[];
		shouldDisplay: boolean;
		step: number;
	}>(captchaInitialValue);
	const [captchaResendCode, setCaptchaResendCode] = useState<{
		attempts: string[];
		shouldDisplay: boolean;
	}>(captchaResendCodeInitialValue);
	const errorInputStyle = hasError ? styles.errorInput : {};
	const dispatch = useDispatch();
	const currPlatform = useDevicePlatform();
	const originalCode = useRef<CodeType>(CodeType.SMS);
	const captchaRef = useRef<any>();
	const clipboardInterval = useRef<number>();
	const clipboardDisabled = useRef<boolean>(false);
	const canListenToClipboard =
		Platform.OS === 'android' ||
		(currPlatform === DeviceType.MOBILE_WEB && !getIsSafari());
	const diabledSend =
		(!step ? !username || phone.length < 10 : password?.length < 6) ||
		((displayCaptchaData.shouldDisplay &&
			displayCaptchaData.step === step) ||
		captchaResendCode.shouldDisplay
			? !captchaVerified
			: false);

	useEffect(() => {
		error && dispatch(resetLoginError());
	}, [phone]);

	useEffect(() => {
		if (
			step &&
			password?.length === 6 &&
			!clipboardInterval.current &&
			canListenToClipboard &&
			!clipboardDisabled.current
		) {
			login();
		}
	}, [password]);

	const listenToClipboard = async () => {
		if (canListenToClipboard) {
			const tempData = await clipboard.getClipboardText();
			lastClipboardText.current =
				typeof tempData === 'string'
					? tempData
					: lastClipboardText.current;
			clearInterval(clipboardInterval.current);
			clipboardInterval.current = setInterval(
				() =>
					checkPinCodeFromClipBoard(
						setPassword,
						clipboardInterval,
						lastClipboardText,
						clipboardDisabled,
					),
				400,
			);
		} else {
			clearInterval(clipboardInterval.current);
		}
	};

	useEffect(() => {
		(async () => {
			const data = await getItemFromLocalStorage('displayCaptchaData');
			data && setDisplayCaptchaData(JSON.parse(data));
			setPassword('');
		})();
		return () => {
			error && dispatch(resetLoginError());
			clearInterval(clipboardInterval.current);
			setPassword('');
			setItemToLocalStorage(
				'displayCaptchaData',
				JSON.stringify(captchaInitialValue),
			);
		};
	}, []);

	useEffect(() => {
		setItemToLocalStorage(
			'displayCaptchaData',
			JSON.stringify(displayCaptchaData),
		);
	}, [displayCaptchaData]);

	const updatePhone = (value: string) => {
		if (!value.match(/^[0-9]*$/)) {
			return;
		}
		setPhoneNumber(value);
	};
	const handlerKeyEnter = (e: any) => {
		if (e.nativeEvent.key === 'Enter') {
			login();
		}
	};

	const handlerKeyEnterFirsStep = (e: any) => {
		if (e.nativeEvent.key === 'Enter') {
			sendCode(CodeType.SMS);
		}
	};

	const sendCode = async (requestType: CodeType) => {
		console.log("send code")
		const data = { requestType, phone, login: username };
		try {
			dispatch(loginRequest());
			const res: any = await httpService.api({
				type: 'loginWithCode1',
				data,
				timeout: 30000,
			});
			if (step) {
				const captchaData = getShouldDisplayCaptch(
					captchaResendCode.attempts,
					MAX_LOGIN_ATTEMPTS,
					MAX_LOGIN_ATTEMPTS_TIME_SECOND,
				);
				setCaptchaResendCode(captchaData);
				Platform.OS === 'web' &&
					captchaData.shouldDisplay &&
					captchaRef.current?.reset();
			}

			setDisplayCaptchaData({
				...captchaInitialValue,
				step: 1,
			});
			setCaptchaVerified(false);
			originalCode.current = requestType;
			setStep(1);
			listenToClipboard();
		} catch (err) {
			if (!step) {
				setDisplayCaptchaData({
					...getShouldDisplayCaptch(
						displayCaptchaData.attempts,
						MAX_LOGIN_ATTEMPTS,
						MAX_LOGIN_ATTEMPTS_TIME_FIRST,
					),
					step: 0,
				});
				setCaptchaVerified(false);
				Platform.OS === 'web' && captchaRef.current?.reset();
				logEvent({
					eventName: EventName.loginStep1Failure,
					data: {
						data,
						error: err,
					},
				});
				dispatch(
					loginFailure(
						err
							? ({ errorMessage: 'WRONG_MOBILE' } as any)
							: ({ errorMessage: 'NO_CONNECTION' } as any),
					),
				);
			} else {
				logEvent({
					eventName: EventName.loginStep2ResendFailure,
					data: {
						data,
						error: err,
					},
				});
			}
		}
	};

	const login = async () => {
		console.log("login")
		const data = { password, login: username };
		try {
			dispatch(loginRequest());
			const res: any = await httpService.api({
				type: 'loginWithCode2',
				data,
				timeout: 30000,
			});
			setItemToLocalStorage(
				'displayCaptchaData',
				JSON.stringify(captchaInitialValue),
			);
			dispatch(loginStep2SuccessCallback(res));
		} catch (err) {
			setDisplayCaptchaData({
				...getShouldDisplayCaptch(
					displayCaptchaData.attempts,
					MAX_LOGIN_ATTEMPTS,
					MAX_LOGIN_ATTEMPTS_TIME_SECOND,
				),
				step: 1,
			});
			setCaptchaVerified(false);
			Platform.OS === 'web' && captchaRef.current?.reset();
			logEvent({
				eventName: EventName.loginStep2Failure,
				data: {
					data,
					error: err,
				},
			});
			dispatch(
				loginFailure(
					err
						? ({ errorMessage: 'WRONG_CODE' } as any)
						: ({ errorMessage: 'NO_CONNECTION' } as any),
				),
			);
		}
	};

	const onCaptchaChange = (value: any) => {
		setCaptchaVerified(true);
	};

	const onExpiredCaptcha = () => {
		setCaptchaVerified(false);
	};

	return (
		<>
			<I18n size={14} style={{ paddingBottom: 10 }}>
				{!step ? 'login.loginWithCode.desc' : 'login.putCodeDesc'}
			</I18n>
			<View style={[styles.credentials, { height: step ? 46 : 115 }]}>
				{!step ? (
					<>
						<View style={[styles.inputContainer, errorInputStyle]}>
							<TextInput
								style={styles.textInput}
								underlineColorAndroid='transparent'
								placeholder={i18nService.translate(
									currPlatform === DeviceType.WEB ||
										currPlatform === DeviceType.MOBILE_WEB
										? 'signin.teudatZehut'
										: 'signin.web.teudatZehut',
								)}
								value={username}
								onChangeText={setUsername}
								onKeyPress={handlerKeyEnterFirsStep}
							/>
							<ImageIcon name={'user'} width={20} height={20} />
						</View>
						<View
							style={[styles.inputContainer, {}, errorInputStyle]}
						>
							<TextInput
								style={styles.textInput}
								underlineColorAndroid='transparent'
								placeholder={i18nService.translate(
									'general.phone',
								)}
								maxLength={10}
								keyboardType='numeric'
								value={phone}
								onChangeText={updatePhone}
								onKeyPress={handlerKeyEnterFirsStep}
							/>
							<ImageIcon
								name={'phone'}
								width={14}
								height={24}
								style={{ marginLeft: 4 }}
							/>
						</View>
					</>
				) : (
					<View style={[styles.inputContainer, {}, errorInputStyle]}>
						<TextInput
							style={styles.textInput}
							underlineColorAndroid='transparent'
							placeholder={i18nService.translate('login.putCode')}
							maxLength={6}
							value={password}
							{...Platform.select({
								web: { autoComplete: 'one-time-code' },
							})}
							onChangeText={setPassword}
							keyboardType='number-pad'
							spellCheck={false}
							autoCorrect={false}
							onKeyPress={handlerKeyEnter}
							textContentType={
								Platform.OS === 'ios' ? 'oneTimeCode' : 'none'
							}
						/>
						<ImageIcon name={'phoneCode'} width={24} height={24} />
					</View>
				)}
			</View>
			{error}
			{((displayCaptchaData.shouldDisplay &&
				displayCaptchaData.step === step) ||
				captchaResendCode.shouldDisplay) &&
			Platform.OS === 'web' ? (
				<View
					style={{
						marginVertical: 15,
					}}
				>
					{
						<ReCAPTCHA
							ref={captchaRef}
							onChange={onCaptchaChange}
							onExpired={onExpiredCaptcha}
							sitekey={RECAPTCHA_SITE_KEY}
						/>
					}
				</View>
			) : null}
			<SolidButtonContainer
				disabled={diabledSend}
				overrideStyle={{
					height: 61,
					alignItems: 'center',
					justifyContent: 'center',
					marginTop:
						displayCaptchaData.shouldDisplay &&
						displayCaptchaData.step === step &&
						Platform.OS === 'web'
							? 20
							: 35,
				}}
				onPress={() => {
					!step ? sendCode(CodeType.SMS) : login();
				}}
			>
				<I18n size={16} weight='normal' color='white'>
					{!step ? 'login.getCode' : 'general.enterence'}
				</I18n>
			</SolidButtonContainer>
			<LinkButtonContainer
				onPress={() =>
					sendCode(!step ? CodeType.VOICE_MAIL : originalCode.current)
				}
				overrideStyle={{ width: !step ? 135 : 90 }}
				disabled={
					(!step && diabledSend) ||
					(captchaResendCode.shouldDisplay &&
						!captchaVerified &&
						Platform.OS === 'web')
				}
			>
				<I18n size={14} weight='400' color='lightPrimary'>
					{!step ? 'login.getCodeByPhone' : 'login.resendCode'}
				</I18n>
			</LinkButtonContainer>
		</>
	);
};

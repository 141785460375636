import React, { useEffect, useRef, useState } from 'react';
import {
	View,
	StyleSheet,
	TouchableOpacity,
	TextInput,
	Platform,
	Text
} from 'react-native';
import I18n from '../../../components/shared/i18n/I18n.component';
import colors from '../../../config/colors';
import { ButtonsContainerSubmitForm} from '../PersonalInformation.utils';
import useDevicePlatform, {
	DeviceType,
} from '../../../hooks/useDevicePlatform.hook';
import httpService from '../../../services/http.service';
import { WorkerCardModel } from '../../../models/workerCard.model';
import moment from 'moment';
import { modalService } from '../../../services/modal.service';
import AlertModal from '../../../modals/AlertModal/AlertModal.component';
import Loader from '../../../components/shared/Loader/Loader.component';
import {  SubmitFormWithPassword } from '../../Report101Page/SubmitFormWithPassword';
import { SubmitFormWithCode } from '../../Report101Page/SubmitFormWithCode';
import { UserDetails } from '../PersonalInformation.container';

interface Props {
	saveData: any;
	dataToServer: (
		values: any,
		initiaValues: any,
		duplicate: boolean,
		saveData?: any,
		isFromDraft?: boolean,
	) => Promise<any>;
	values: WorkerCardModel;
	onResponse(ans: any): void;
	onNext(val: any): void;
	onCancel: (callback: Function) => void;
}

export enum SubmitType {
	password = 'password',
	code = 'code',
}

export enum CodeType {
	SMS = 'SMS',
	VOICE_MAIL = 'VOICE_MAIL',
}
export enum verifySubmitType {
	SMS = 'sms',
	PASSWORD = 'password',
}

const tabs = [
	{
		id: SubmitType.password,
		title: 'passwordTabText',
	},
	{ id: SubmitType.code, title: 'otpTabText' },
];


const SubmitFormAuthModal = ({
	saveData,
	dataToServer,
	values,
	onResponse,
	onNext
}: Props) => {
	const platform = useDevicePlatform();
	const [password, setPassword] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [submitType, setSubmitType] = useState(SubmitType.password);
	const [phone, setPhoneNumber] = useState<string>('');
	const [step, setStep] = useState<number>(0);
	const [code, setCode] = useState<string>('');
	const [teudatZeut, setTeudatZeut] = useState<string>();

	const isWeb = platform === DeviceType.WEB;

	const errorString = "Failed to verify user password.401";
	const errorStringOtp = "Failed to verify code.400";

	const disabledSend = submitType === SubmitType.code ? !step ? !phone : !code : !password

	useEffect(() => {
		getPersonalData()
	},[]);

	const getPersonalData = async () => {
		try {
			const res: UserDetails = await httpService.api<any>({
				type: 'getPersonalDetails',
			});
			if (res) {
				setTeudatZeut(res.teudatZeut);
			}
		} catch (e) {}
	};

	
	const onSubmitForm = async () => {
		try {
			setIsLoading(true);
			const res = await httpService.api({
				type: 'submitWorkerCard',
				params: {
					formId: saveData.id,
				},
				isFormData: true,
				notGenerateFormData: true,
				data: await dataToServer(values, values, false, {
					...saveData,
					submitDate: moment().format('DD/MM/YYYY'),
					verifyPassword: submitType === SubmitType.password ? password : code,
					verifyType : submitType === SubmitType.password ? verifySubmitType.PASSWORD : verifySubmitType.SMS
				}, false),
			});
			setIsLoading(false);
			if(Platform.OS !== "web" && res === errorString){
				await modalService.openModal(
                    null,
                    {
                        submitBtnText: 'general.close',
                        iconName: 'attention',
                    },
                    (props: any) => (
                        <AlertModal {...props}>
                            <I18n>workerCard.editor.submit.failureSubmitPassword</I18n>
                        </AlertModal>
                    ),
                );
                return;
			}
			onNext(onResponse({ res: res, resText: 'success' }));
		} catch (e: any) {
			if(e?.data === errorString){
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>workerCard.editor.submit.failureSubmitPassword</I18n>
						</AlertModal>
					),
				);
			}else if(e?.data === errorStringOtp && submitType === SubmitType.code){
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>login.wrongCode</I18n>
						</AlertModal>
					),
				);
			}else if(e.data.includes("PDF merge problem for entity")){
					await modalService.openModal(
						null,
						{
							submitBtnText: 'general.close',
							iconName: 'attention',
						},
						(props: any) => (
							<AlertModal {...props}>
								<I18n>workerCard.editor.submit.failureUploadFiles</I18n>
							</AlertModal>
						),
					);
			}else{
				await modalService.openModal(
					null,
					{
						submitBtnText: 'general.close',
						iconName: 'attention',
					},
					(props: any) => (
						<AlertModal {...props}>
							<I18n>workerCard.editor.submit.failureSubmitForm</I18n>
						</AlertModal>
					),
				);
			}
		}
	};

	const sendCode = async (requestType : CodeType) => {
		const data = { phone , teudatZeut ,requestType};
		try {
			const res: any = await httpService.api({
				type: 'otpStep1',
				data,
				timeout: 30000,
			});
			setStep(1);
		} catch (err) {
			await modalService.openModal(
				null,
				{
					submitBtnText: 'general.close',
					iconName: 'attention',
				},
				(props: any) => (
					<AlertModal {...props}>
						<I18n>workerCard.editor.submit.failureVerifyPhone</I18n>
					</AlertModal>
				),
			);
		}
	};

	return (
		<>
			<View style={isWeb ? styles.modalContainer : styles.modalContainerMobile}>
				<I18n size={isWeb ? 26 : 20} weight='500' color={'darkGrey'}>
					workerCard.pull.validUserTitle
				</I18n>
				<View
					style={{
						flexDirection: 'row-reverse',
						width: '100%',
						justifyContent:"space-between"
					}}
				>
					{tabs.map(t => (
						<TouchableOpacity
							onPress={() =>
								setSubmitType(t.id)
							}
							style={{
								width:"50%",
								padding: 10,
								borderBottomWidth: 5,
								borderBottomColor:
									submitType === t.id
										? colors.primary
										: colors.white,
							}}
						>
							<I18n style={{textAlign:"center"}}>{`workerCard.pull.${t.title}`}</I18n>
						</TouchableOpacity>
					))}
				</View>

				<View style={{width:'100%' }}>
					<View>
						{
							submitType === SubmitType.password ?
							(
								<SubmitFormWithPassword password={password} setPassword={setPassword}/>
								
							) :
							(
								<SubmitFormWithCode step={step} phone={phone} setPhoneNumber={setPhoneNumber} code={code} setCode={setCode}/>
							)
						}
					</View>
					
				</View>

				<ButtonsContainerSubmitForm
					acceptText={
						submitType === SubmitType.password ?
						'workerCard.pull.send101' :
						'workerCard.pull.sendCode' 
					}
					onSubmitForm={onSubmitForm}
					sendCode={sendCode}
					onCancel={onNext}
					disabled={disabledSend}
					submitType={submitType}
					step={step}
				/>

			</View>
			{isLoading && <Loader />}
		</>
	);
};

const styles = StyleSheet.create({
	modalContainer: {
		display: 'flex',
		padding:32,
		flexDirection: 'column',
        alignItems:"flex-end",
		...Platform.select({
			web: {gap:"32px"},
		}),
		backgroundColor: colors.white,
		borderRadius:8,
		width:448
	},
	modalContainerMobile: {
		display: 'flex',
		padding:32,
		flexDirection: 'column',
        alignItems:"flex-end",
		height: 400,
		justifyContent: "space-between",
		backgroundColor: colors.white,
		borderRadius:8,
		width:350
	},
	divider: {
		backgroundColor: colors.darkGrey,
		opacity: 0.2,
		height: 1,
		width: '100%',
		marginVertical: 20,
	},
	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		marginTop: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
	popupTitle: {
		color: colors.darkGrey,
		fontWeight:"500",
		textAlign:"right"
	},
});

export default SubmitFormAuthModal;

import React, { useState } from 'react';

import { TextInput, View, Platform, TouchableOpacity , StyleSheet,} from 'react-native';
import I18n from '../../components/shared/i18n/I18n.component';
import colors from '../../config/colors';
import ImageIcon from '../../components/shared/Icon/ImageIcon.component';


interface SubmitFormWithPasswordProps {
	password: string;
	setPassword: React.Dispatch<React.SetStateAction<string>>
}

export const SubmitFormWithPassword = ({
	password,
	setPassword
}: SubmitFormWithPasswordProps) => {
		const [showCurrentPassword, setShowCurrentPassword] = useState(false);
		const handleChange = (value: any) => {
			setPassword(value);
		};
		
	

	return (
		<>
			<I18n size={14} weight='normal'>
				workerCard.pull.addPortalPassword
            </I18n>
            <View style={styles.inputContainer}>
                <TouchableOpacity
                    onPress={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                    }
                >
                    <ImageIcon
                        name={
                            showCurrentPassword
                                ? 'showPassword'
                                : 'hidePassword'
                        }
                        width={22}
                        height={14}
                    />
                </TouchableOpacity>
                <TextInput
                    style={[styles.textInput, { width: 220 }]}
                    underlineColorAndroid='transparent'
                    value={password}
                    onChangeText={e => handleChange(e)}
                    secureTextEntry={!showCurrentPassword}
                />
            </View>
		</>
	);
};

const styles = StyleSheet.create({

	inputContainer: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		height: 46,
		paddingRight: 20,
		paddingLeft: 15,
		borderRadius: 5,
		marginTop: 5,
		backgroundColor: '#ecf3f7',
	},
	textInput: {
		textAlign: 'right',
		fontSize: 14,
		width: '100%',
		fontWeight: '500',
		color: colors.darkGrey,
		height: '100%',
		...Platform.select({
			web: {
				outlineWidth: 0,
			},
		}),
	},
});

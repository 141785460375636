import { ApiMap } from '../interfaces/http.interface';
import { mockDataMap } from '../__mocks__/httpResponse.mock';

export const apiMap: ApiMap = {
	getPortalStatus: {
		url: 'api/s/app/status',
		method: 'get',
		noAuthHeader: true,
	},
	getEmployers: {
		url: 'api/p/customers',
		method: 'get',
		mock: mockDataMap.employersNames,
	},
	startWorklog: {
		url: 'api/p/worklog/',
		method: 'post',
		mock: mockDataMap.startWorlog,
	},
	stopWorklog: {
		url: 'api/p/worklog/',
		method: 'put',
		mock: mockDataMap.testMock,
	},
	workLogByIdManager: {
		url: 'api/p/work-log/manager/<%= workLogId %>',
		method: 'get',
	},
	workLogByIdEmployee: {
		url: 'api/p/work-log/<%= workLogId %>',
		method: 'get',
	},
	editWorkLogManagerById: {
		url: 'api/p/work-log/manager/<%= workLogId %>/edit',
		method: 'post',
	},
	editWorkLogEmployeeById: {
		url: 'api/p/work-log/<%= workLogId %>/edit',
		method: 'post',
	},
	activeWorkLog: {
		url: 'api/p/worklog/',
		method: 'get',
		mock: mockDataMap.workLogById,
	},
	addWorkLogByProjectId: {
		url: 'api/p/reports/worklog/employee',
		method: 'post',
		mock: mockDataMap.addWorkLogByProjectId,
	},
	updateWorkLogById: {
		url: 'api/p/reports/worklog',
		method: 'put',
		mock: mockDataMap.workLogById,
	},
	updateWorkLogByIdForManager: {
		url: 'api/p/reports/worklog/manager',
		method: 'put',
		mock: mockDataMap.workLogById,
	},
	deleteWorkLogById: {
		url: 'api/p/worklog',
		method: 'delete',
		mock: {},
	},
	deleteWorkLogByIdForManager: {
		url: 'api/p/worklog/manager',
		method: 'delete',
		mock: {},
	},
	reportsByMonth: {
		url: 'api/p/work-log/clock/calendar/?year=<%= year %>&month=<%= month %>',
		method: 'get',
	},
	login: {
		url: 'api/auth/login',
		method: 'post',
		noAuthHeader: true,
		mock: mockDataMap.login,
	},
	loginWithCode1: {
		url: 'api/auth/login-step1',
		method: 'post',
		noAuthHeader: true,
		mock: mockDataMap.login,
	},
	loginWithCode2: {
		url: 'api/auth/login-step2',
		method: 'post',
		noAuthHeader: true,
		mock: mockDataMap.login,
	},
	otpStep1: {
		url: 'api/auth/otp-step1',
		method: 'post',
		noAuthHeader: true,
		mock: mockDataMap.login,
	},
	logout: {
		url: 'api/auth/logout',
		method: 'put',
		mock: null,
	},
	refreshToken: {
		url: 'api/auth/refreshToken',
		method: 'post',
		mock: mockDataMap.refreshToken,
		noRepHeader: true,
	},
	forgotPassword: {
		url: 'api/auth/forgetPassword',
		method: 'post',
		noAuthHeader: true,
		mock: mockDataMap.forgotPassword,
	},
	verifyForgetPassword: {
		url: 'api/auth/verifyForgetPassword',
		method: 'post',
		noAuthHeader: true,
		mock: mockDataMap.verifyNewPassword,
	},
	resetPassword: {
		url: 'api/auth/resetPassword',
		method: 'post',
		mock: null,
	},
	getCurrentDateTime: {
		url: 'api/p/server/time',
		method: 'get',
		mock: mockDataMap.currentTime,
	},
	autocompleteCustomer: {
		url: 'api/p/reports/filter/autocomplete?s=<%= s %>&pageName=<%= pageName %>',
		method: 'get',
		mock: mockDataMap.filterOptions,
	},
	getEmployeeNameList: {
		url: 'api/p/reports/filter/employee?pageName=<%= pageName %>',
		method: 'get',
		mock: mockDataMap.filterOptions,
	},
	getProjectNameList: {
		url: 'api/p/reports/filter/projects',
		method: 'get',
		mock: mockDataMap.filterOptions,
	},
	getAllProjectNameList: {
		url: 'api/p/reports/filter/all-projects',
		method: 'get',
		mock: mockDataMap.filterOptions,
	},
	getEventNameList: {
		url: 'api/p/reports/filter/events',
		method: 'get',
		mock: mockDataMap.filterOptions,
	},
	getAllEventNameList: {
		url: 'api/p/reports/filter/all-events',
		method: 'get',
		mock: mockDataMap.filterOptions,
	},
	getUserData: {
		url: 'api/p/user',
		method: 'get',
		mock: mockDataMap.user,
		noRepHeader: true,
	},
	changeProject: {
		url: 'api/p/worklog/project/change',
		method: 'post',
		mock: null,
	},
	connectToPushNotification: {
		url: 'api/p/fcm/token',
		method: 'post',
		mock: null,
	},
	getPeychecksRange: {
		url: 'api/p/paychecks/range',
		method: 'get',
		mock: mockDataMap.peychecksRange,
	},
	getOneHandredSixReportRange: {
		url: 'api/p/externalReports/106/years',
		method: 'get',
		mock: mockDataMap.ohsRange,
	},
	getOneHandredSixReport: {
		url: 'api/p/externalReports/106',
		method: 'get',
	},
	getOneHandredOneReportRange: {
		url: 'api/p/employee-form',
		method: 'get',
	},
	getOneHandredOneReport: {
		url: 'api/p/externalReports/106',
		method: 'get',
	},
	getAttendanceReportRange: {
		url: 'api/p/externalReports/10001/months',
		method: 'get',
		mock: mockDataMap.attendanceRange,
	},
	getAttendanceReport: {
		url: 'api/p/externalReports/10001',
		method: 'get',
	},
	getAttendanceReportWithWorker: {
		url: 'api/p/externalReports/10001/employee/<%= workerId %>?year=<%= year %>&month=<%= month %>&unitId=<%=unitId%>',
		method: 'get',
	},
	getPeycheckData: {
		url: 'api/p/paychecks?year=<%= year %>&month=<%= month %>&unitId=<%=unitId%>',
		method: 'get',
		mock: mockDataMap.paycheckData,
	},
	getPeycheckDataWithWorker: {
		url: 'api/p/paychecks/id',
		method: 'get',
		mock: mockDataMap.paycheckData,
	},
	getVacationReports: {
		url: 'api/p/reports/vacation',
		method: 'post',
	},
	eventPunchin: {
		url: 'api/p/worklog/event/punchin',
		method: 'post',
		mock: null,
	},
	getSalaryReportList: {
		url: 'api/p/paychecks/lists',
		method: 'post',
		mock: mockDataMap.fullSalaryReportListData,
	},
	getSalaryReportListYearsFilter: {
		url: 'api/p/reports/filter/paycheck/years',
		method: 'get',
		mock: mockDataMap.salaryReportListfiltersDataYears,
	},
	getVacationReportsForEmployees: {
		url: 'api/p/reports/vacation/employees',
		method: 'post',
	},
	getEmployeesFilterList: {
		url: 'api/p/reports/filter/customer_employees',
		method: 'post',
	},
	getDepartmentsFilterList: {
		url: 'api/p/reports/filter/departments',
		method: 'get',
	},
	getVacationReportsExcelFile: {
		url: 'api/p/reports/vacation/export',
		method: 'post',
	},
	getDetailedCostingReportsForEmployees: {
		url: 'api/p/reports/costing/employees',
		method: 'post',
	},
	getDetailedCostingReports: {
		url: 'api/p/reports/costing',
		method: 'post',
	},
	getAttendanceReportList: {
		url: 'api/p/externalReports/10001/list',
		method: 'post',
	},
	getAttendanceReportListYearsFilter: {
		url: 'api/p/externalReports/10001/years',
		method: 'get',
	},
	getUserReports: {
		url: 'api/p/admin/user_report',
		method: 'post',
	},
	getRolesFilterList: {
		url: 'api/p/admin/filter/role',
		method: 'post',
	},
	getCompareCostReportsExcelFile: {
		url: 'api/p/reports/costing/comparing/export',
		method: 'post',
	},
	getDetailedCostingReportsExcelFile: {
		url: 'api/p/reports/costing/export',
		method: 'post',
	},
	getCostCompareReportsList: {
		url: 'api/p/reports/costing/comparing',
		method: 'post',
	},
	getCostCompareEmployeesReportsList: {
		url: 'api/p/reports/costing/comparing/employees',
		method: 'post',
	},
	getUserDetails: {
		url: 'api/p/admin/user_report/<%= id %>/<%= unitId %>',
		method: 'get',
	},
	updateUserDetails: {
		url: 'api/p/admin/user_report/<%= id %>',
		method: 'post',
	},
	getRolePermissions: {
		url: 'api/p/admin/role/<%= id %>/permission',
		method: 'get',
	},
	getRoles: {
		url: 'api/p/admin/filter/role',
		method: 'post',
	},
	getUsersExcel: {
		url: 'api/p/admin/user_report/export',
		method: 'post',
	},
	createUser: {
		url: 'api/p/admin/user_report/create',
		method: 'post',
	},
	sendDocumentsToEmployee: {
		url: 'api/p/docs/manager/send',
		method: 'post',
	},
	sendReminderToEmployee: {
		url: 'api/p/docs/manager/<%= id %>/reminder',
		method: 'post',
	},
	deleteDocument: {
		url: 'api/p/docs/manager/<%= id %>/delete',
		method: 'post',
	},
	getWorkerCardData: {
		url: 'api/p/employee-form/<%= formId %>',
		method: 'get',
	},
	getWorkerCardInEdit: {
		url: 'api/p/employee-form/<%= formId %>/get-for-update',
		method: 'get',
	},
	createWorkerCard: {
		url: 'api/p/employee-form/new',
		method: 'post',
	},
	updateWorkerCard: {
		url: 'api/p/employee-form/<%= formId %>',
		method: 'post',
	},
	submitWorkerCard: {
		url: 'api/p/employee-form/<%= formId %>/submit-new',
		method: 'post',
	},
	getWorkerCardFile: {
		url: 'api/p/employee-form/<%= formId %>/file/<%= fileId %>',
		method: 'get',
	},
	getWorkerCardFileManager: {
		url: 'api/p/employee-form/manager/<%= formId %>/file/<%= fileId %>',
		method: 'get',
	},
	getTemplateFiles: {
		url: 'api/p/employee-form/template',
		method: 'get',
	},
	getFontTemplateFile: {
		url: 'api/p/employee-form/font',
		method: 'get',
	},
	getFromMonthToMonthReportsList: {
		url: 'api/p/work-paper-draft',
		method: 'post',
	},
	getFromMonthToMonthReportsListUpdateMode: {
		url: 'api/p/work-paper-draft/update-mode',
		method: 'post',
	},
	getFromMonthToMonthReportsExcelFile: {
		url: 'api/p/work-paper-draft/export',
		method: 'post',
	},
	getCustomerWageComponents: {
		url: 'api/p/work-paper-draft',
		method: 'get',
	},
	getDisplayComponents: {
		url: 'api/p/work-paper-draft/display',
		method: 'get',
	},
	getMaxParameter: {
		url: 'api/p/work-paper-draft/undo/max',
		method: 'get',
	},
	getUndoAction: {
		url: 'api/p/work-paper-draft/undo',
		method: 'get',
	},
	makeUndoAction: {
		url: 'api/p/work-paper-draft/undo',
		method: 'post',
	},
	getCustomerDraftData: {
		url: 'api/p/work-paper-draft/<%= id %>',
		method: 'get',
	},
	getCustomerDraftDataWithDraftId: {
		url: 'api/p/work-paper-draft/<%= id %>/<%= draftId %>',
		method: 'get',
	},
	getPreviousMonthsList: {
		url: 'api/p/work-paper-draft/<%= id %>/last',
		method: 'get',
	},
	saveCustomerDraftData: {
		url: 'api/p/work-paper-draft/<%= id %>/<%= draftId %>/save',
		method: 'post',
	},
	getComponentsDraftData: {
		url: 'api/p/work-paper-draft/component',
		method: 'post',
	},
	saveDisplayComponent: {
		url: 'api/p/work-paper-draft/display',
		method: 'post',
	},
	removeDisplayComponent: {
		url: 'api/p/work-paper-draft/display/delete',
		method: 'post',
	},
	saveWorkPaperDraftData: {
		url: 'api/p/work-paper-draft/save',
		method: 'post',
	},
	approveFromMonthToMonthReportsList: {
		url: 'api/p/work-paper-draft/approve',
		method: 'post',
	},
	approveCustomerDraftData: {
		url: 'api/p/work-paper-draft/<%= id %>/<%= draftId %>/approve',
		method: 'post',
	},
	disapproveDraft: {
		url:"api/p/work-paper-draft/<%= profileId %>/<%= draftId %>/disapprove",
		method:"post"
	},
	disapproveMultipleDrafts: {
		url:"api/p/work-paper-draft/disapprove",
		method:"post"
	},
	getReport101List: {
		url: 'api/p/employee-form/manager',
		method: 'post',
	},
	getReport101ListYearsFilter: {
		url: 'api/p/employee-form/manager/filter/tax-year',
		method: 'get',
	},
	getEmployeeWorkerCard: {
		url: 'api/p/employee-form/manager/<%= formId %>',
		method: 'get',
	},
	pullWorkerCardFromEmployer: {
		url: 'api/p/employee-form/<%= formId %>/pull',
		method: 'post',
	},
	addEmployerComment: {
		url: 'api/p/employee-form/manager/<%= formId %>/comment',
		method: 'post',
	},
	updateEmployerComment: {
		url: 'api/p/employee-form/manager/<%= formId %>/comment/<%= commentId %>',
		method: 'post',
	},
	deleteEmployerComment: {
		url: 'api/p/employee-form/manager/<%= formId %>/comment/<%= commentId %>/delete',
		method: 'post',
	},
	approve101Form: {
		url: 'api/p/employee-form/manager/<%= formId %>/approve',
		method: 'post',
	},
	reject101Form: {
		url: 'api/p/employee-form/manager/<%= formId %>/reject',
		method: 'post',
	},
	getEmployeeDashboard: {
		url: 'api/p/dashboard',
		method: 'get',
	},
	getEmployeeForm101Attachments: {
		url: 'api/p/employee-form/<%= formId %>/attachments',
		method: 'get',
	},
	getManagerForm101Attachments: {
		url: 'api/p/employee-form/manager/<%= formId %>/attachments',
		method: 'get',
	},
	getWorkerCardDataManager: {
		url: 'api/p/employee-form/manager/<%= formId %>',
		method: 'get',
	},
	updateReport101Status: {
		url: 'api/p/employee-form/manager/<%= formId %>/pull',
		method: 'post',
	},
	getWorkerCardFinalFile: {
		url: 'api/p/employee-form/<%= formId %>/file',
		method: 'get',
	},
	getWorkerCardFinalFileManager: {
		url: 'api/p/employee-form/manager/<%= formId %>/file',
		method: 'get',
	},
	getWorkerCardFinalFileManagerDiff: {
		url: 'api/p/employee-form/manager/<%= formId %>/diff',
		method: 'get',
	},
	createWorkerCardFileByManager: {
		url: 'api/p/employee-form/manager/<%= formId %>/file',
		method: 'post',
	},
	getWorkerCardStatus: {
		url: 'api/p/employee-form/<%= formId %>/status',
		method: 'get',
	},
	approveWorkerCard: {
		url: 'api/p/employee-form/manager/approve',
		method: 'post',
	},
	sendUpdateReminderWorkerCard: {
		url: 'api/p/employee-form/manager/reminder',
		method: 'post',
	},
	getManagerDashboard: {
		url: 'api/p/dashboard/manager',
		method: 'get',
	},
	getVacationReportLastDate: {
		url: 'api/p/reports/vacation/last',
		method: 'get',
	},
	getCostingReportLastDate: {
		url: 'api/p/reports/costing/last',
		method: 'get',
	},
	getPaycheckReportLastDate: {
		url: 'api/p/paychecks/last',
		method: 'get',
	},
	getAttendanceReportLastDate: {
		url: 'api/p/externalReports/10001/last',
		method: 'get',
	},
	getTaxCreditAddressList: {
		url: 'api/p/employee-form/tax-credit-address',
		method: 'get',
	},
	checkIfAllowCreateNew101Report: {
		url: 'api/p/employee-form/allow-create',
		method: 'get',
	},
	getDataForNew101Report: {
		url: 'api/p/employee-form/new',
		method: 'get',
	},
	getApplicationsToEmployerData: {
		url: 'api/p/employer-request',
		method: 'post',
	},
	getApplicationsAttachments: {
		url: 'api/p/employer-request/<%= requestId %>/attachments',
		method: 'get',
	},
	cancelWorkerCard: {
		url: 'api/p/employee-form/manager/cancel',
		method: 'post',
	},
	getEmployeeRequests: {
		url: 'api/p/employer-request/manager',
		method: 'post',
	},
	getEmployeeRequestsAttachments: {
		url: 'api/p/employer-request/manager/<%= requestId %>/attachments',
		method: 'get',
	},
	getEmployerRequestDetails: {
		url: 'api/p/employer-request/<%= requestId %>',
		method: 'get',
	},
	getEmployerRequestStatus: {
		url: 'api/p/employer-request/<%= requestId %>/status',
		method: 'get',
	},
	createEmployerRequest: {
		url: 'api/p/employer-request/create',
		method: 'post',
	},
	editEmployerRequest: {
		url: 'api/p/employer-request/<%= requestId %>',
		method: 'post',
	},
	cancelEmployerRequest: {
		url: 'api/p/employer-request/<%= requestId %>/cancel',
		method: 'post',
	},
	getEmployerRequestFileUrl: {
		url: 'api/p/employer-request/<%= requestId %>/file/<%= fileId %>',
		method: 'get',
	},
	getCurrentVacationBalance: {
		url: 'api/p/employer-request/vacation-balance',
		method: 'get',
	},
	getManagerRequestDetails: {
		url: 'api/p/employer-request/manager/<%= requestId %>',
		method: 'get',
	},
	getManagerRequestStatus: {
		url: 'api/p/employer-request/manager/<%= requestId %>/status',
		method: 'get',
	},
	approveRequest: {
		url: 'api/p/employer-request/manager/<%= requestId %>/approve',
		method: 'post',
	},
	approveEditRequest: {
		url: 'api/p/employer-request/manager/<%= requestId %>/edit-and-approve',
		method: 'post',
	},
	rejectRequest: {
		url: 'api/p/employer-request/manager/<%= requestId %>/reject',
		method: 'post',
	},
	getManagerRequestFiles: {
		url: 'api/p/employer-request/manager/<%= requestId %>/file/<%= fileId %>',
		method: 'get',
	},
	approveMultipleRequests: {
		url: 'api/p/employer-request/manager/approve',
		method: 'post',
	},
	getHoursReportSummery: {
		url: 'api/p/work-log/manager/report/monthly',
		method: 'post',
	},
	getReportsSummery: {
		url: 'api/p/work-log/manager/report',
		method: 'post',
	},
	getEmployeeReportsSummery: {
		url: 'api/p/work-log/report',
		method: 'post',
	},
	getEmployeeReportSummery: {
		url: 'api/p/work-log/manager/<%= profileId %>/report',
		method: 'post',
	},
	getEmployeeStandardByDate: {
		url: 'api/p/work-log/standard/<%= date %>',
		method: 'get',
	},
	getStandardByDate: {
		url: 'api/p/work-log/standard/<%= date %>',
		method: 'get',
	},
	getStandardByDateManager: {
		url: 'api/p/work-log/manager/standard/<%= profileAid %>/<%= date %>',
		method: 'get',
	},
	approveEmployeeReport: {
		url: 'api/p/work-log/manager/<%= profileId %>/approve',
		method: 'post',
	},
	approveReport: {
		url: 'api/p/work-log/manager/report/approve',
		method: 'post',
	},
	deleteEmployeeReport: {
		url: 'api/p/work-log/manager/<%= workLogId %>/delete',
		method: 'post',
	},
	deleteEmployeeReportByEmployee: {
		url: 'api/p/work-log/<%= workLogId %>/delete',
		method: 'post',
	},
	getYearsOfHoursReportSummery: {
		url: 'api/p/work-log/manager/years',
		method: 'get',
	},
	getMinAppVersion: {
		url: 'api/s/app-version/<%= platform %>',
		method: 'get',
		noAuthHeader: true,
	},
	getWorkPaper: {
		url: 'api/p/work-paper',
		method: 'post',
	},
	getLastWorkPaper: {
		url: 'api/p/work-paper/last',
		method: 'get',
	},
	exportWorkPaper: {
		url: 'api/p/work-paper/export',
		method: 'post',
	},
	updateMultiplePermisions: {
		url: 'api/p/admin/user_report/update_employee_role',
		method: 'post',
	},
	getMonthOfHoursReportSummery: {
		url: 'api/p/work-log/manager/report/monthly/months',
		method: 'get',
	},
	getEmpMonthOfHoursReportSummery: {
		url: 'api/p/work-log/report/months',
		method: 'get',
	},
	getPersonalDetails: {
		url: 'api/p/user-detail',
		method: 'get',
	},
	updateCustomer: {
		url: 'api/p/user-detail/update-customer',
		method: 'post',
	},
	updateDisplayMode: {
		url: 'api/p/user-detail/update-display-mode',
		method: 'post',
	},
	updateDisplayWageData: {
		url: 'api/p/user-detail/update-display-wage-data',
		method: 'post',
	},
	getUserSettings: {
		url: 'api/p/user-settings',
		method: 'get',
	},
	updatePassword: {
		url: 'api/p/user-detail/update-password',
		method: 'post',
	},
	updateUserEmail: {
		url: 'api/p/user-detail',
		method: 'post',
	},
	updateUserPhone: {
		url: 'api/p/user-detail/update-phone',
		method: 'post',
	},
	verifyUserEmail: {
		url: 'api/auth/update-mail?token=<%= key %>',
		method: 'get',
		noAuthHeader: true,
	},
	createWorkLogByManager: {
		url: 'api/p/work-log/manager/create',
		method: 'post',
	},
	createWorkLogByEmployee: {
		url: 'api/p/work-log/create',
		method: 'post',
	},
	exportPaycheckList: {
		url: 'api/p/paychecks/lists/export',
		method: 'post',
	},
	export101List: {
		url: 'api/p/employee-form/manager/export',
		method: 'post',
	},
	getCustomerSettings: {
		url: 'api/p/customer/setting',
		method: 'get',
	},
	sendCustomerSettings: {
		url: 'api/p/customer/setting',
		method: 'post',
	},
	getWorkLogListForClock: {
		url: 'api/p/work-log/clock/<%= date %>',
		method: 'get',
	},
	startClockWorklog: {
		url: 'api/p/work-log/clock/start',
		method: 'post',
	},
	exitClockWorklogById: {
		url: 'api/p/work-log/clock/<%= workLogId %>/exit',
		method: 'post',
	},
	exitClockWorkLog: {
		url: 'api/p/work-log/clock/exit',
		method: 'post',
	},
	employeeGetTotalReportedByDate: {
		url: 'api/p/work-log/total/<%= date %>',
		method: 'get',
	},
	managerGetTotalReportedByDate: {
		url: 'api/p/work-log/manager/total/<%= profileAid %>/<%= date %>',
		method: 'get',
	},
	createNewLocation: {
		url: 'api/p/location',
		method: 'post',
	},
	getLocationData: {
		url: 'api/p/location/<%= locationId %>',
		method: 'get',
	},
	updateLocation: {
		url: 'api/p/location/<%= locationId %>',
		method: 'post',
	},
	getUsersByDepartments: {
		url: 'api/p/reports/filter/departments-employees',
		method: 'post',
	},
	getUsersByDepartmentsAndUnits: {
		url: 'api/p/reports/filter/departments-employees-by-unit',
		method: 'post',
	},
	deleteLocation: {
		url: 'api/p/location/<%= locationId %>/delete',
		method: 'post',
	},
	getExistingLocation: {
		url: 'api/p/location/<%= locationId %>',
		method: 'get',
	},
	getLocationsList: {
		url: 'api/p/location/report',
		method: 'post',
	},
	getLocationFilter: {
		url: 'api/p/reports/filter/location',
		method: 'get',
	},
	associateLocation: {
		url: 'api/p/location/associate',
		method: 'post',
	},
	getIosLink: {
		url: 'api/p/app/link/IOS',
		method: 'get',
	},
	getAndroidLink: {
		url: 'api/p/app/link/ANDROID',
		method: 'get',
	},
	getBusinessUnitList: {
		url: 'api/p/reports/filter/business-unit',
		method: 'get',
	},
	updateHpSettings: {
		url: 'api/p/user-detail/hp-setting',
		method: 'post',
	},
	getUnitProjectsFilter: {
		url: 'api/p/reports/filter/unit-projects',
		method: 'get',
	},
	getRepCustomers: {
		url: 'api/p/rep-customer',
		method: 'post',
	},
	getRepCustomerDetails: {
		url: 'api/p/rep-customer/<%= customerId %>',
		method: 'get',
	},
	getPaychecksBusinessUnits: {
		url: 'api/p/paychecks/units?year=<%= year %>&month=<%= month %>',
		method: 'get',
	},
	getExternalReportsBusinessUnits: {
		url: 'api/p/externalReports/<%= ReportType %>/units?year=<%= year %>&month=<%= month %>',
		method: 'get',
	},
	getExternalReportByDateAndUnit: {
		url: 'api/p/externalReports/<%= ReportType %>?year=<%= year %>&month=<%= month %>&unitId=<%=unitId%>',
		method: 'get',
	},
	createRepCustomer: {
		url: 'api/p/rep-customer/create',
		method: 'post',
	},
	updateRepCustomer: {
		url: 'api/p/rep-customer/<%= customerId %>',
		method: 'post',
	},
	updateRepStatus: {
		url: 'api/p/rep-customer/<%= customerId %>/update-status?active=<%= active %>',
		method: 'post',
	},
	getRepresntUnits: {
		url: 'api/p/reports/filter/represent-unit',
		method: 'get',
	},
	getRepCustomerUnitsList: {
		url: 'api/p/reports/filter/rep-customer-units',
		method: 'post',
	},
	getRepEnterData: {
		url: 'api/p/rep-customer/<%= customerId %>/access',
		method: 'get',
	},
	getManagerDocs: {
		url: 'api/p/docs/manager',
		method: 'post',
	},
	getManagerDocsFilter: {
		url: 'api/p/docs/manager/filter',
		method: 'post',
	},
	getDocs: {
		url: 'api/p/docs',
		method: 'get',
	},
	getDocsFilter: {
		url: 'api/p/docs/filter?s=<%= search %>',
		method: 'get',
	},
	getDocsDownloadFile: {
		url: 'api/p/docs/file/<%= docId %>',
		method: 'get',
	},
	getDocsDownloadManagerFile: {
		url: 'api/p/docs/manager/file/<%= docId %>',
		method: 'get',
	},
	getCustomerOperatorsList: {
		url: 'api/p/rep-customer/operators',
		method: 'get',
	},
	updateRepCustomerBusinessUnit: {
		url: 'api/p/rep-customer/<%= customerId %>/<%= unitId %>',
		method: 'post',
	},
	getRepresentOrgReport: {
		url: 'api/p/rep-reports/org-reports/<%= type %>',
		method: 'post',
	},
	getRepresentPaycheckReport: {
		url: 'api/p/rep-reports/paycheck',
		method: 'post',
	},
	getRepresentForm106Report: {
		url: 'api/p/rep-reports/form-106',
		method: 'post',
	},
	getRepresentForm101Report: {
		url: 'api/p/rep-reports/employee-form',
		method: 'post',
	},
	getRepresentWorkPeperReport: {
		url: 'api/p/rep-reports/work-paper-draft',
		method: 'post',
	},
	exportRepresentsReports: {
		url: 'api/p/rep-reports/<%= reportName %>/export',
		method: 'post',
	},
	exportRepresentsOrgReports: {
		url: 'api/p/rep-reports/org-reports/<%= type %>/export',
		method: 'post',
	},
	updateOrgReportStatus: {
		url: 'api/p/rep-reports/org-reports/<%= type %>/<%= id %>',
		method: 'post',
	},
	getRepDashboardMonthlyData: {
		url: 'api/p/dashboard/represent/monthly',
		method: 'post',
	},
	getRepDashboardYearlyData: {
		url: 'api/p/dashboard/represent/yearly',
		method: 'post',
	},
	getRepDashboardForm101: {
		url: 'api/p/dashboard/represent/employee-form',
		method: 'post',
	},
	getRepDashboardReportFromMonthToMonth: {
		url: 'api/p/dashboard/represent/work-paper-draft',
		method: 'post',
	},
	getRepDashboardView: {
		url: 'api/p/dashboard/represent/display',
		method: 'get',
	},
	updateRepDashboardView: {
		url: 'api/p/dashboard/represent/display/<%= type %>',
		method: 'post',
	},
	updateEmployeeFormAlert: {
		url: 'api/p/user-detail/update-employee-form-alert',
		method: 'post',
	},
	updateDocumentAlert: {
		url: 'api/p/user-detail/update-document-alert',
		method: 'post',
	}
};
